import { T } from '@transifex/react';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { MemberCardProps } from '../../../types/Member';
import { Card } from '../../common';
import { MemberNeedLabel } from '../../common/ActionItem';
import Avatar from '../../common/Avatar';
import { MemberJourneyStatusLabel } from '../../common/Member';
import { Tag } from '../../common/Tag';
import Text from '../../common/Text';
import MemberButton from './MemberButton';

const MemberCard = ({
  firstName,
  lastName,
  clientName,
  need,
  numberOfActiveServiceRequests,
  userId,
  engagementStatus,
  memberStatus,
}: MemberCardProps) => {
  const router = useRouter();

  const goToMemberDashboard = useCallback(() => router.push(`/member/${userId}`), [router, userId]);

  return (
    <Card
      borderColor="lightGrayOne"
      direction="row"
      leftInset
      rounding="slight"
      className={`justify-start items-stretch border-l-[0px]`}
    >
      <div className="flex-1 flex-row p-[16px] gap-[8px]">
        <div className="flex flex-col justify-space-between gap-[24px]">
          <div className="flex flex-row gap-[16px]">
            <Avatar name={`${firstName} ${lastName}`} outline="darkGrayOne" />
            <div className="flex flex-col">
              <div className="flex flex-row gap-[8px]">
                <Text privacyLevel="pii" variant="h4" color="darkGrayOne">
                  {`${firstName} ${lastName}`}
                </Text>
                {memberStatus?.memberJourneyStatus && (
                  <Tag
                    bgColor="lightGrayTwo"
                    text={<MemberJourneyStatusLabel status={memberStatus.memberJourneyStatus} />}
                    rounded="full"
                  />
                )}
                {(numberOfActiveServiceRequests ?? 0) > 0 && (
                  <Text
                    privacyLevel="public"
                    variant="label2"
                    color="white"
                    className="bg-purple rounded-[16px] py-[4px] px-[8px]"
                  >
                    <T
                      _str="{numberOfActiveServiceRequests} ACTIVE SERVICE REQ."
                      numberOfActiveServiceRequests={numberOfActiveServiceRequests}
                    />
                  </Text>
                )}
              </div>
              {clientName && (
                <Text privacyLevel="phi" variant="label2" color="darkGrayOne">
                  {clientName}
                </Text>
              )}
            </div>
          </div>
          {need && (
            <div className="flex flex-col items-start gap-[8px]">
              <Text privacyLevel="public" color="darkGrayTwo" variant="label3">
                <T _str="TOP NEED" />
              </Text>
              <Text
                privacyLevel="public"
                variant="label2"
                color="darkGrayTwo"
                className="bg-lightGrayTwo rounded-[16px] px-[8px] py-[4px]"
              >
                <MemberNeedLabel need={need} />
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className="py-[8px] px-[16px]">
        <MemberButton fullName={`${firstName} ${lastName}`} onClick={goToMemberDashboard} />
      </div>
    </Card>
  );
};

export default MemberCard;
