import { T } from '@transifex/react';
import React from 'react';

import { hoursUrl } from '../../utils/const';
import { Card, TextLink } from '../common';
import Text from '../common/Text';
import Clock from '../icons/Clock';

const LogHoursBanner = () => {
  return (
    <Card
      direction="row"
      noBorder
      className={`justify-start px-[16px] py-[8px] mt-[8px] align-center flex flex-row items-center`}
    >
      <Clock className="mr-2" />
      <Text privacyLevel="public" variant="body2" color="darkGrayOne">
        <T _str="Log your time in" />{' '}
      </Text>
      <TextLink privacyLevel="public" className="ml-[4px]" link={hoursUrl}>
        <T _str="Justworks Hours" />
      </TextLink>
      <Text privacyLevel="public" variant="body2" color="darkGrayOne">
        <T _str="." />
      </Text>
    </Card>
  );
};

export default LogHoursBanner;
