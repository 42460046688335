import classnames from 'classnames';
import Link from 'next/link';
import { useCallback } from 'react';

import { INavItem } from '../../../types/layout';
import Text from '../../common/Text';

export const DuosNavItem = ({ isActive, label, url, external, icon }: INavItem) => {
  const Icon = icon;

  const renderNavItem = useCallback(
    () => (
      <div
        className={classnames(
          'hover:cursor-pointer flex px-8 py-5 relative hover:bg-white hover:bg-opacity-10',
          isActive && 'bg-white bg-opacity-10'
        )}
      >
        <Icon colorOverride="white" active={isActive} className="mr-5" />
        <Text
          privacyLevel="public"
          tabIndex={-1}
          className={classnames(isActive ? 'opacity-100' : 'opacity-70')}
          variant="body1"
          color="white"
        >
          {label}
        </Text>
        {isActive && <div className="absolute top-0 right-[-3px] w-[6px] rounded-[8px] h-[64px]" />}
      </div>
    ),
    [isActive, label, Icon]
  );

  return external ? (
    <a href={url} target="_blank" rel="noreferrer">
      {renderNavItem()}
    </a>
  ) : (
    <Link href={url} target="_blank" passHref>
      {renderNavItem()}
    </Link>
  );
};
