import { EffectCallback } from 'react';
import { useDeepCompareEffect, useFirstMountState } from 'react-use';

export default function useDeepComareUpdateEffect(effect: EffectCallback, deps: any[]) {
  const isFirstMount = useFirstMountState();

  return useDeepCompareEffect(() => {
    if (!isFirstMount) {
      return effect();
    }
  }, deps);
}
