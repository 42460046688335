import { T } from '@transifex/react';
import React from 'react';

import { Card, Text } from '../common';

export const EmptyMemberList = () => {
  return (
    <Card className="px-4 py-6">
      <Text privacyLevel="public" variant="h4" color="darkGrayOne">
        <T _str="No members match your search or filter criteria." />
      </Text>
      <Text privacyLevel="public" variant="body1" color="darkGrayOne" className="mt-2">
        <T _str="Please modify your criteria and try again." />
      </Text>
    </Card>
  );
};
