import { useT } from '@transifex/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { toBackgroundColor } from '../../theme/colors';
import { ColorType } from '../../types/ui';
import { HelpCenterLink, Tooltip } from '../common';
import Avatar from '../common/Avatar';
import Text from '../common/Text';
import Logo from '../icons/Logo';

type HeaderColorVariants = 'default' | 'purple';

type DuosHeaderProps = {
  name?: string;
  variant?: HeaderColorVariants;
  logoOnClick?: () => void;
  className?: string;
  hideHelpIcon?: boolean;
};

const DuosHeader = ({
  name,
  logoOnClick,
  className = '',
  hideHelpIcon = false,
  variant = 'default',
}: DuosHeaderProps) => {
  const t = useT();

  const helpMessage = t('Need some help? Visit the Help Center to report an issue.');

  const logoColor = useMemo((): ColorType => {
    switch (variant) {
      case 'default': {
        return 'purple';
      }
      case 'purple': {
        return 'white';
      }
    }
  }, [variant]);

  const backgroundColor = useMemo((): string => {
    switch (variant) {
      case 'default': {
        return toBackgroundColor('white');
      }
      case 'purple': {
        return toBackgroundColor('purple');
      }
    }
  }, [variant]);
  return (
    <div
      className={classNames(
        'absolute t-[0px] mt-[-56px] w-full bg-white h-[56px] flex flex-row items-center px-[48px] py-[16px] z-30',
        `${name ? 'justify-between' : 'justify-start'}`,
        backgroundColor,
        className,
        { 'top-[40.5px]': process.env.APP_ENV === 'dev', 'mt-[0px]': process.env.APP_ENV === 'dev' }
      )}
    >
      <Logo onClick={logoOnClick} />
      {name && (
        <div className="flex flex-row items-center gap-[24px]">
          {/* Hiding this for now. <Bell /> */}
          {!hideHelpIcon && (
            <Tooltip message={helpMessage} popoverPositions={['left']} verticalAlign="top">
              <HelpCenterLink isIcon />
            </Tooltip>
          )}
          <div className="flex flex-row items-center gap-[8px]">
            <Avatar name={name} textColor={'darkGrayOne'} />
            <Text privacyLevel="public" variant="label1" color="purple">
              {name}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default DuosHeader;
