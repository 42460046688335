import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import LogHoursBanner from '../components/home/LogHoursBanner';
import { MembersList } from '../components/home/MembersList';
import WelcomeBanner from '../components/home/WelcomeBanner';
import Layout from '../components/layout/Layout';
import { useUserSession } from '../hooks/api';
import { MEMBER_HOME } from '../routes';

const HomePage = () => {
  const { data: userSession, isLoading: isLoadingSession } = useUserSession();
  const isMember = userSession?.role === 'member';

  useEffect(() => {
    console.log(`NEXT_PUBLIC_FF_CONSOLE_LOG_MESSAGE: ${process.env.NEXT_PUBLIC_FF_CONSOLE_LOG_MESSAGE}`);
  }, []);

  const router = useRouter();

  // Redirect to the correct home page if you're a member
  useEffect(() => {
    if (!isLoadingSession && isMember) {
      router.push(MEMBER_HOME);
    }
  }, [isMember, isLoadingSession, router]);

  // Don't load the page if your session is still loading or you're a member
  if (isLoadingSession || isMember) {
    // @todo: replace with skeleton UI
    return null;
  }

  return (
    <Layout page="Duos">
      <div className="flex-1 flex items-stretch gap-[24px] h-full">
        <div className="flex-1 flex-col gap-[32px] bg-warmWhite">
          <WelcomeBanner name={userSession?.name} />
          <LogHoursBanner />
          <div className="mb-6 h-[calc(100%-168px)] mt-8">
            <MembersList userSession={userSession} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
