import { T } from '@transifex/react';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { INavItem } from '../../../types/layout';
import { calendarUrl, libraryUrl } from '../../../utils/const';
import { CalendarIcon, HomeIcon, LibraryIcon } from '../../icons';
import { DuosNavItem } from './DuosNavItem';

export const DuosNav = () => {
  const router = useRouter();
  const path = router.asPath;

  const MAIN_NAV_ITEMS: INavItem[] = useMemo(
    () => [
      {
        key: 'home',
        label: <T _str="Home" />,
        icon: HomeIcon,
        url: '/',
      },
      {
        key: 'calendar',
        label: <T _str="Calendar" />,
        icon: CalendarIcon,
        url: calendarUrl,
        external: true,
      },
      {
        key: 'library',
        label: <T _str="Library" />,
        icon: LibraryIcon,
        url: libraryUrl,
        external: true,
      },
    ],
    []
  );

  return (
    <div className="min-h-full w-[217px] bg-purple py-14">
      <div className="nav-content flex flex-col gap-[16px]">
        {MAIN_NAV_ITEMS.map((navItem) => (
          <DuosNavItem {...navItem} key={navItem.key} isActive={navItem.url === path} />
        ))}
      </div>
    </div>
  );
};
