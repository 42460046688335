import type { NextPage } from 'next';

import HomePage from '../containers/HomePage';
import { getServerSideProps } from '../utils/i18nServerSideProps';

const Home: NextPage = () => {
  return <HomePage />;
};

export { getServerSideProps };
export default Home;
