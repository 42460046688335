import { useT } from '@transifex/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { Text } from '../common';
import { HomeIcon, RightIcon } from '../icons';

export type NavItem = {
  label: string;
  path: string;
};

type Props = {
  navItems?: NavItem[];
};

export const Breadcrumbs = (props: Props) => {
  const t = useT();
  const router = useRouter();
  const query = router.query;
  const pathname = router.pathname;
  const paths = pathname.split('/');

  const routes: Record<string, any> = useMemo(
    () => ({
      member: {
        '[userId]': {
          annualWellness: t('Annual Wellness Visit'),
          item: {
            '[itemId]': t('Detail View'),
          },
          recommendations: t('All Recommendations'),
          serviceRequests: {
            index: t('All Service Requests'),
            '[requestId]': t('Detail View'),
          },
          info: t('Member Information'),
          callHistory: t('Call History'),
          index: t('Member Dashboard'),
          resourceLookup: {
            index: t('Resource Lookup'),
          },
          savedResources: {
            index: t('Saved Member Resources'),
          },
          sharedResources: {
            index: t('Shared RLT Resources'),
          },
        },
      },
      planLookup: {
        '[memberId]': t('Plan Lookup'),
      },
      login: {
        email: t('Login'),
        index: t('Login'),
      },
      caremanager: {
        resourceLookup: t('Resource Lookup'),
      },
    }),
    [t]
  );

  const navItems = useMemo(() => {
    if (props.navItems) {
      return props.navItems;
    }

    if (paths.length === 2) return [];

    const items: NavItem[] = [];
    let url: string;
    let routeObj = routes;

    paths.forEach((pathItem) => {
      if (pathItem === '') {
        items.push({
          label: t('Home'),
          path: '/',
        });
        url = '/';
      } else {
        routeObj = routeObj[pathItem];

        const uri =
          pathItem.charAt(0) === '[' && pathItem.charAt(pathItem.length - 1) === ']'
            ? query[pathItem.slice(1, pathItem.length - 1)]
            : pathItem;
        url += uri + '/';

        if (routeObj.index) {
          items.push({
            label: routeObj.index,
            path: url,
          });
        } else if (typeof routeObj === 'string') {
          items.push({
            label: routeObj,
            path: url,
          });
        }
      }
    });

    return items;
  }, [props.navItems, paths, routes, t, query]);

  return navItems.length > 0 ? (
    <div className="flex flex-row items-center">
      <HomeIcon colorOverride="darkGrayThree" className="mr-[16px]" active />
      {navItems.map((link: NavItem, index) =>
        navItems.length - 1 > index ? (
          <div key={`${link.label}-link`} className="flex flex-row items-center gap-[4px]">
            <Link href={link.path}>
              {
                <button aria-label={`${link.label}`}>
                  <Text
                    privacyLevel="public"
                    tabIndex={-1}
                    color="darkGrayThree"
                    variant="body1"
                    fontWeight="medium"
                    className="underline hover:no-underline cursor-pointer"
                  >
                    {link.label}
                  </Text>
                </button>
              }
            </Link>
            {index < navItems.length - 1 && <RightIcon colorOverride="darkGrayThree" />}
          </div>
        ) : (
          <Text
            privacyLevel="public"
            key={`${link.label}-text`}
            color="darkGrayThree"
            variant="body1"
            fontWeight="medium"
            className="underline hover:no-underline cursor-pointer"
          >
            {link.label}
          </Text>
        )
      )}
    </div>
  ) : null;
};
