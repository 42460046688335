import { yupResolver } from '@hookform/resolvers/yup';
import { T } from '@transifex/react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useGetMemberCardList } from '../../hooks/api';
import useDeepComareUpdateEffect from '../../hooks/useDeepCompareUpdateEffect';
import { MemberFilterOption } from '../../types/Member';
import { UserSession } from '../../types/UserSession';
import { containsPhoneNumber } from '../../utils/misc';
import { Button, LoadingSpinner } from '../common';
import { MemberJourneyStatusLabel } from '../common/Member';
import Text from '../common/Text';
import { EmptyMemberList } from './EmptyMemberList';
import MemberCard from './MemberCard';
import { MembersDisplayOption } from './MembersDisplayOption';
import { SearchMember } from './SearchMember';

type MembersListProps = {
  userSession?: UserSession;
};

export const searchMembersSchema = Yup.object().shape({
  query: Yup.string(),
  status: Yup.array(Yup.string()),
  client: Yup.array(Yup.string()),
});

export const MembersList = ({ userSession }: MembersListProps) => {
  const isDuo = userSession?.role === 'duo';
  const { data: memberCards, isLoading } = useGetMemberCardList(!isDuo, {
    enabled: !!userSession,
  });

  const memberListRef = useRef<HTMLDivElement>(null);

  // Header state
  const [display, setDisplay] = useState(15);
  const [page, setPage] = useState(1);

  const defaultValues: MemberFilterOption = {
    query: '',
    status: [],
    client: [],
  };

  const [filter, setFilter] = useState<MemberFilterOption>(defaultValues);
  const { query, status, client } = filter;

  const resolver = yupResolver(searchMembersSchema);
  const form = useForm({ defaultValues: defaultValues, resolver, mode: 'onChange' });
  const { handleSubmit, formState } = form;
  const isValid = formState.isValid;

  const onSubmit = useMemo(() => {
    return handleSubmit((data) => {
      setFilter({ ...data });
    });
  }, [handleSubmit, setFilter]);

  const watchValues = form.watch();

  useDeepComareUpdateEffect(() => {
    if (isValid) {
      onSubmit();
    }
  }, [watchValues, isValid, onSubmit]);

  const filteredMembers = useMemo(() => {
    setPage(1);
    memberListRef.current?.scrollTo(0, 0);

    if ((query.length === 0 && status.length === 0 && client.length === 0) || !memberCards) return memberCards ?? [];

    let result = memberCards;

    if (query) {
      const loQuery = query.toLowerCase();
      result = result.filter(
        ({ userId, firstName, lastName, phone, dob, email }) =>
          `${firstName} ${lastName}`.toLowerCase().indexOf(loQuery) > -1 ||
          (phone && containsPhoneNumber(phone, loQuery)) ||
          (dob && dob.indexOf(loQuery) > -1) ||
          (dob && dob.indexOf(moment(loQuery).format('YYYY-MM-DD')) > -1) ||
          (email && email.indexOf(loQuery) > -1) ||
          (userId && userId.indexOf(loQuery) > -1)
      );
    }

    if (status.length) {
      result = result.filter(
        ({ memberStatus }) => memberStatus?.memberJourneyStatus && status.includes(memberStatus?.memberJourneyStatus)
      );
    }

    if (client.length) {
      result = result.filter(({ clientName }) => clientName && client.includes(clientName));
    }

    return result;
  }, [memberCards, query, status, client]);

  const maxPages = useMemo(() => Math.ceil((filteredMembers.length ?? 0) / display), [filteredMembers, display]);

  useEffect(() => {
    setPage(1);
    memberListRef.current?.scrollTo(0, 0);
  }, [display]);

  const setNextPage = useCallback(() => setPage((p) => p + 1), []);

  const statuses = useMemo(() => {
    if (memberCards) {
      const allStatuses = [
        ...new Set(memberCards.map(({ memberStatus }) => memberStatus?.memberJourneyStatus).filter(Boolean)),
      ];
      return allStatuses.map((status) => ({
        value: status!,
        label: <MemberJourneyStatusLabel status={status} />,
      }));
    }

    return [];
  }, [memberCards]);

  const clients = useMemo(() => {
    if (memberCards) {
      const allClients = [...new Set(memberCards.map(({ clientName }) => clientName).filter(Boolean))];
      return allClients.map((client) => ({
        value: client!,
        label: client!,
      }));
    }

    return [];
  }, [memberCards]);

  const hasFilterApplied = useMemo(() => !!(query || client.length || status.length), [query, client, status]);

  if (isLoading || !userSession || !memberCards) {
    // @todo: replace with skeleton UI
    return <LoadingSpinner colorOverride="purple" size={48} />;
  }

  return (
    <div className="flex flex-col gap-[16px] h-full">
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <SearchMember filter={filter} statuses={statuses} clients={clients} />
        </form>
      </FormProvider>
      <div className="flex items-center justify-between">
        <Text privacyLevel="public" variant="h4" color="darkGrayOne">
          <T _str="My Members ({numberOfMembers})" numberOfMembers={filteredMembers.length} />
        </Text>
        <MembersDisplayOption display={display} onDisplayChange={(val) => setDisplay(val)} />
      </div>
      {filteredMembers.length > 0 && (
        <div ref={memberListRef} className="flex flex-col gap-[16px] overflow-y-scroll h-[calc(100%-168px)]">
          {filteredMembers.slice(0, page * display).map((cardProps) => (
            <MemberCard key={cardProps.userId} {...cardProps} />
          ))}
          {page < maxPages && (
            <Button onClick={setNextPage} fullWidth>
              <T _str="View More Members" />
            </Button>
          )}
        </div>
      )}
      {hasFilterApplied && filteredMembers.length === 0 && <EmptyMemberList />}
    </div>
  );
};
