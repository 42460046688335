import { T } from '@transifex/react';
import React from 'react';

import { Card } from '../../common';
import Text from '../../common/Text';
import Welcome from '../../icons/Welcome';

const WelcomeBanner = ({ name }: { name?: string }) => {
  return (
    <Card noBorder direction="row" className={`flex-row justify-start items-center px-[16px] py-[20px] gap-[16px]`}>
      <Welcome />
      <div className="flex flex-col">
        <Text privacyLevel="public" variant="h3" color="darkGrayOne">
          <T _str="Hello" /> <span className="fs-mask text-magenta ml-[4px]">{name}!</span>
        </Text>
        <Text privacyLevel="public" fontWeight="medium" color="darkGrayTwo" variant="subtitle-1">
          <T _str="Let's see what you have going on this week..." />
        </Text>
      </div>
    </Card>
  );
};

export default WelcomeBanner;
