import { T, useT } from '@transifex/react';
import { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import useToggle from '../../../hooks/useToggle';
import { Option } from '../../../types/Common';
import { MemberFilterOption, MemberJourneyStatus } from '../../../types/Member';
import { Card, DropdownCheckboxFilter, IconButton, Text, TextInput } from '../../common';
import FitlerToggleButton from '../../common/ResourceLookupTool/SearchSection/FilterToggleButton';
import Search from '../../icons/Search';

type SearchMemberProps = {
  filter: MemberFilterOption;
  statuses: Option<MemberJourneyStatus>[];
  clients: Option<string>[];
};

export const SearchMember = ({ filter, statuses, clients }: SearchMemberProps) => {
  const t = useT();
  const { state: showFilters, toggle: toggleFilters } = useToggle(false);
  const { setValue, control } = useFormContext();

  const clearQuery = useCallback(() => {
    setValue('query', '');
  }, [setValue]);

  const [queryWatch] = useWatch({ name: ['query'], control });

  return (
    <Card className="px-5 py-6">
      <Text privacyLevel="public" variant="h4" color="darkGrayOne">
        <T _str="Search for Member" />
      </Text>
      <Text privacyLevel="public" variant="body2" color="darkGrayOne" className="mt-2">
        <T _str="Search by member's name, phone number, member ID, or date of birth." />
      </Text>
      <div className="flex items-center mt-4 w-full">
        <Controller
          name="query"
          render={({ field }) => {
            return (
              <TextInput
                fill
                startAdornment={<Search />}
                endAdorment={
                  <IconButton
                    label={t('Clear member search term')}
                    className={queryWatch === '' ? 'hidden' : 'flex'}
                    color="purple"
                    icon="close"
                    onClick={clearQuery}
                  />
                }
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="divider bg-lightGrayOne h-[1px] w-full mt-4 mb-2" />
      <FitlerToggleButton showFilters={showFilters} onClick={toggleFilters} />

      {showFilters && (
        <div className="flex items-center gap-4 mt-4">
          <Text privacyLevel="public" variant="label2" className="-mr-2">
            <T _str="Filter By:" />
          </Text>
          <DropdownCheckboxFilter
            name="status"
            options={statuses}
            label={t('Status') + (filter.status.length ? ` (${filter.status.length})` : '')}
          />
          <DropdownCheckboxFilter
            options={clients}
            name="client"
            label={t('Client') + (filter.client.length ? ` (${filter.client.length})` : '')}
          />
        </div>
      )}
    </Card>
  );
};
