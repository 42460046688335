import classNames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';

import { useUserSession } from '../../hooks/api';
import { useAlertContext, useCallContext } from '../../hooks/contexts';
import Alert from '../common/Alert';
import { Breadcrumbs, NavItem } from './Breadcrumbs';
import DuosHeader from './DuosHeader';
import { DuosNav } from './DuosNav';

type Props = PropsWithChildren<{
  page?: string;
  navItems?: NavItem[];
}>;

const Layout = ({ children, page, navItems }: Props) => {
  const router = useRouter();
  const userSessionQuery = useUserSession();
  const { primaryCall, dialpadState } = useCallContext();
  const { alertMessage, alertStyle, clearAlert, alertType } = useAlertContext();

  const callBannerCorrection = primaryCall ? 'mb-[104px]' : '';
  const dialpadCorrection = dialpadState !== 'close' ? 'pb-[86px]' : '';
  return (
    <>
      <Head>
        <title>{page ?? 'DUOS Inc.'}</title>
        <meta name="description" content={page ?? 'DUOS Inc.'} />
      </Head>
      <div
        className={classNames([
          process.env.APP_ENV === 'dev' ? 'mt-[96.5px]' : 'mt-[56px]',
          'min-h-[calc(100svh-56px)] w-full flex flex-col justify-start items-center',
        ])}
      >
        <DuosHeader name={userSessionQuery.data?.name} logoOnClick={() => router.push('/')} />
        <div className={classNames('min-h-[calc(100svh-56px)] w-full flex flex-row items-stretch')}>
          <DuosNav />
          <div
            className={classNames(
              'bg-warmWhite flex-1 items-stretch p-6 flex flex-col gap-4',
              callBannerCorrection,
              dialpadCorrection
            )}
          >
            <Breadcrumbs navItems={navItems} />
            {alertMessage && (
              <div className="w-full flex flex-col gap-[16px] relative">
                <Alert
                  privacyLevel="pii"
                  className={classNames('absolute top-[24px] left-0', alertStyle)}
                  onClose={clearAlert}
                  error={alertType === 'error'}
                >
                  {alertMessage}
                </Alert>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
