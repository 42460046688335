export type Option<T extends string> = { value: T; label: string | JSX.Element };

export type PaginationParam = {
  limit: number;
  offset: number;
};

export type Location = {
  address?: string;
  address2?: string;
  cityName?: string;
  regionName?: string;
  zipCode?: string;
};

export enum Pronouns {
  HeHim = 'He/Him',
  SheHer = 'She/Her',
  TheyThem = 'They/Them',
}

export enum Gender {
  Male = 'm',
  Female = 'f',
  Other = 'o',
  PreferNotToSay = 'p',
}

export type PaginatedResults<T> = {
  data: T[];
  errors: any[];
  meta: PaginationParam & { total: number };
};
