import { T, useT } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';
import { MouseEventHandler } from 'react';

import { Text } from '../..';
import { icons } from '../../../icons';

type FilterToggleButtonProps = {
  className?: string;
  onClick?: MouseEventHandler | undefined;
  showFilters?: boolean;
};

const FitlerToggleButton = ({ className, onClick, showFilters }: FilterToggleButtonProps) => {
  const t = useT();
  const AccordionIcon = icons[showFilters ? 'down-circle' : 'right-circle'];
  return (
    <button
      className={classNames(className, 'flex items-center gap-2')}
      aria-label={showFilters ? t('Hide Filters') : t('Show Filters')}
      tabIndex={0}
      onClick={onClick}
      type="button"
    >
      <AccordionIcon size={32} colorOverride="purple" />
      <Text privacyLevel="public" variant="body1" color="darkGrayOne">
        <T _str="View Filters" />
      </Text>
    </button>
  );
};

export default FitlerToggleButton;
