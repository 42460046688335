import classNames from 'classnames';
import { useMemo } from 'react';

import { toBackgroundColor } from '../../theme/colors';
import { TextType } from '../../theme/typography';
import { ColorType, IconProps, IconType } from '../../types/ui';
import { icons } from '../icons';
import Text from './Text';

type Rounded = 'full' | 'default' | 'slight';

type TagProps = {
  text: string | JSX.Element;
  size?: string;
  textColor?: ColorType;
  bgColor?: ColorType;
  icon?: IconType;
  iconProps?: IconProps;
  className?: string;
  textVariant?: TextType;
  rounded?: Rounded;
};

export const Tag = ({
  bgColor = 'lightGrayOne',
  icon,
  text,
  iconProps,
  className,
  textColor,
  textVariant = 'tag',
  size = 'md',
  rounded,
}: TagProps) => {
  const sizeClassName = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'py-[2px] px-[8px]';
      case 'md':
        return 'py-[4px] px-[8px]';
      case 'lg':
        return 'py-[8px] px-[16px]';
      default:
        return 'py-[4px] px-[8px]';
    }
  }, [size]);

  const roundingClassName = useMemo(() => {
    switch (rounded) {
      case 'full':
        return 'rounded-full';
      case 'slight':
        return 'rounded-xl';
      case 'default':
        return 'rounded-2xl';
      default:
        return 'rounded-[4px]';
    }
  }, [rounded]);

  return (
    <div
      className={classNames(
        'flex',
        'whitespace-pre-wrap',
        sizeClassName,
        roundingClassName,
        toBackgroundColor(bgColor),
        className
      )}
    >
      <>
        {icon && icons[icon]({ ...iconProps })}
        <Text
          privacyLevel="phi"
          variant={textVariant}
          color={textColor}
          className={classNames(['pl-[4px]', 'pr-[2px]', 'uppercase', ' my-auto', 'whitespace-nowrap'])}
          fontWeight="semibold"
        >
          {text}
        </Text>
      </>
    </div>
  );
};
