import { T, useT } from '@transifex/react';
import React, { useMemo } from 'react';

import { Dropdown, Text } from '../common';

type MembersDisplayOptionProps = {
  display: number;
  onDisplayChange: (value: number) => void;
};

export const MembersDisplayOption = ({ display, onDisplayChange }: MembersDisplayOptionProps) => {
  const t = useT();

  const displayOptions = useMemo(
    () => [
      {
        label: t('15 Members'),
        value: '15',
      },
      {
        label: t('20 Members'),
        value: '20',
      },
      {
        label: t('50 Members'),
        value: '50',
      },
      {
        label: t('100 Members'),
        value: '100',
      },
    ],
    [t]
  );

  const handleDisplayChange = (value: string) => {
    onDisplayChange(+value);
  };

  return (
    <div className="flex items-center gap-[16px]">
      <Text privacyLevel="public" variant="label2">
        <T _str="Display:" />
      </Text>
      <Dropdown
        privacyLevel="public"
        className="w-[200px]"
        placeholder={t('Select')}
        value={display.toString()}
        options={displayOptions}
        btnClassName="!bg-white"
        onChange={handleDisplayChange}
      />
    </div>
  );
};
