import { T } from '@transifex/react';

import { Gender } from '../../types/Common';
import { MemberJourneyStatus } from '../../types/Member';

type MemberJourneyStatusLabelProps = {
  status?: MemberJourneyStatus;
};

export const MemberJourneyStatusLabel = ({ status }: MemberJourneyStatusLabelProps) => {
  if (status === 'Active_Engaged') return <T _str="Active Engaged" />;
  if (status === 'Active_Unengaged') return <T _str="Active Unengaged" />;
  if (status === 'Inactive') return <T _str="Inactive" />;
  else return <></>;
};

type GenederLabelProps = {
  gender?: Gender;
};

export const GenderLabel = ({ gender }: GenederLabelProps) => {
  if (gender === Gender.Male) return <T _str="Male" />;
  if (gender === Gender.Female) return <T _str="Female" />;
  if (gender === Gender.Other) return <T _str="Other" />;
  if (gender === Gender.PreferNotToSay) return <T _str="Prefer Not To Say" />;
  else return <></>;
};
