import { useT } from '@transifex/react';
import classNames from 'classnames';
import React from 'react';

import { toFillColor, toGroupHoverFillColor } from '../../../theme/colors';

const MemberButton = ({ fullName, onClick }: { fullName: string; onClick: () => void }) => {
  const t = useT();
  const normalState = toFillColor('lightGrayTwo');
  const hoverState = toGroupHoverFillColor('lightGrayOne');

  return (
    <button
      className="cursor-pointer hover:fill-lightGrayOne"
      onClick={onClick}
      aria-label={t("Go to ${fullName}'s dashboard", { fullName })}
    >
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="group">
        <circle cx="20" cy="20" r="20" className={classNames(normalState, hoverState)} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6722 9.84274C15.5635 9.95111 15.4773 10.0799 15.4185 10.2216C15.3597 10.3633 15.3294 10.5153 15.3294 10.6687C15.3294 10.8222 15.3597 10.9741 15.4185 11.1159C15.4773 11.2576 15.5635 11.3863 15.6722 11.4947L24.1816 20.0019L15.6722 28.509C15.4531 28.7281 15.33 29.0252 15.33 29.335C15.33 29.6448 15.4531 29.9419 15.6722 30.161C15.8912 30.38 16.1883 30.5031 16.4981 30.5031C16.8079 30.5031 17.1051 30.38 17.3241 30.161L26.6573 20.8278C26.7659 20.7195 26.8521 20.5907 26.9109 20.449C26.9697 20.3073 27 20.1553 27 20.0019C27 19.8484 26.9697 19.6965 26.9109 19.5547C26.8521 19.413 26.7659 19.2842 26.6573 19.1759L17.3241 9.84274C17.2157 9.7341 17.087 9.6479 16.9453 9.58909C16.8035 9.53027 16.6516 9.5 16.4981 9.5C16.3447 9.5 16.1927 9.53027 16.051 9.58909C15.9093 9.6479 15.7805 9.7341 15.6722 9.84274Z"
          className="fill-darkGrayOne stroke-darkGrayOne"
        />
      </svg>
    </button>
  );
};

export default MemberButton;
