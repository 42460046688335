import { T } from '@transifex/react';
import React from 'react';

import { ExternalMemberNeed, InternalMemberNeed } from '../../types/ActionItem';

type MemberNeedLabelProps = {
  need?: InternalMemberNeed;
};

export const MemberNeedLabel = ({ need }: MemberNeedLabelProps) => {
  if (need === InternalMemberNeed.Transportation) return <T _str="Transportation" />;
  if (need === InternalMemberNeed.FinancialAssistance) return <T _str="Financial Assistance" />;
  if (need === InternalMemberNeed.Food) return <T _str="Food" />;
  if (need === InternalMemberNeed.Housing) return <T _str="Housing" />;
  if (need === InternalMemberNeed.FallPrevention) return <T _str="Fall Prevention" />;
  if (need === InternalMemberNeed.SocialEngagement) return <T _str="Social Engagement" />;
  if (need === InternalMemberNeed.AppropriateCoverage) return <T _str="Appropriate Coverage" />;
  if (need === InternalMemberNeed.ConditionSupport) return <T _str="Condition Support" />;
  if (need === InternalMemberNeed.MedicationSupport) return <T _str="Medication Support" />;
  if (need === InternalMemberNeed.AccessToCare) return <T _str="Access to Care" />;
  if (need === InternalMemberNeed.CaregiverSupport) return <T _str="Caregiver Support" />;
  if (need === InternalMemberNeed.Other) return <T _str="Other" />;
  else return <></>;
};

type ExternalMemberNeedLabelProps = {
  need?: ExternalMemberNeed;
};

export const ExternalMemberNeedLabel = ({ need }: ExternalMemberNeedLabelProps) => {
  if (need === ExternalMemberNeed.HealthcareAndHealthInsurance) return <T _str="Health" />;
  if (need === ExternalMemberNeed.Transportation) return <T _str="Transportation" />;
  if (need === ExternalMemberNeed.Food) return <T _str="Food" />;
  if (need === ExternalMemberNeed.HousingAndHelpAroundTheHouse) return <T _str="Home & Care" />;
  if (need === ExternalMemberNeed.FinancialAssistance) return <T _str="Money" />;
  if (need === ExternalMemberNeed.SocialEnrichmentEducationAndFitness) return <T _str="Recreation" />;
  else return <></>;
};
